import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import * as Components from "./components"

import { TCreateValidationSchema } from "./types"
import { config, Translater } from "../../config"
import { useValidation } from "../../hooks"
import { useHistory } from "react-router-dom"
import { ICategory } from "../../types/items"
import { useHttp } from "../../hooks"
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang"
import { TabLanguage } from "../../components/TabLanguage"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { loading, request } = useHttp()
  const history = useHistory()

  const [isClicked, setClicked] = useState(false)

  const [form, setForm] = useState<ICategory>({
    title: [{ value: "", lang: "uk" }],
    hidden: "false",
    parent_id: "",
    restaurant: null,
    photo: "",
    promotionalOffer: "true",
    position: "",
    products: [],
    metaKeywords: [],
    metaDescription: "",
  })

  const [metaKeywords, setMetaKeywords] = useState<string[]>([])
  const [metaDescription, setMetaDescription] = useState<string>("")
  const [valueLang, setValueLang] = useState<string>("uk")

  const [isValid, toggleValid] = useState({
    title: false,
    photo: false,
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      title: {
        condition:
          form.title
            .map((item) => {
              if (!item.value) {
                return false
              }
              return true
            })
            .filter((item) => item).length == 2,
        error: `
          ${Translater.ErrorLanguage[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      photo: {
        condition: !!form.photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      restaurants: {
        condition: !!form.restaurant,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "title") {
        const isTitleInArr = form.title.find((item) => item.lang == valueLang)
          ? true
          : false

        if (isTitleInArr) {
          const title = form.title
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            title: title as { value: string; lang: string }[],
          })

          return
        }

        const title = form.title
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          title: title as { value: string; lang: string }[],
        })

        return
      }

      if (e.target.type === "file" && e?.target?.files) {
        setForm({ ...form, photo: e?.target?.files[0] })
      } else if (e.target.name.split("[")[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id })
    },
    createHandler: async () => {
      try {
        setClicked(true)
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log("createHandler => e", e)
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()

        data.append("title", JSON.stringify(form.title))

        data.append("hidden", form.hidden as string)
        data.append("promotionalOffer", form.promotionalOffer as string)
        data.append("restaurant[]", form?.restaurant?._id as string)
        data.append("photo", form.photo)

        data.append("metaDescription", metaDescription)
        if (metaKeywords.length) {
          metaKeywords.map((el) => data.append("metaKeywords[]", el))
        }

        if (form.parent_id) {
          data.append("parent_id", form.parent_id)
        }

        const response = await request(
          `${config.API}/categories`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          history.push("/categories")

          setForm({
            ...form,
            title: [{ value: "", lang: "" }],
            parent_id: "",
            hidden: "true",
            promotionalOffer: "true",
            photo: "",
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    }
  }, [form, multiLangTitles])

  return (
    <Page.Wrapper title={Translater.CategoriesCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.CategoriesCreatePage.title[language.slug]}
        backButtonLink="/categories"
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />
      <Components.ConfigBlock
        form={form}
        metaKeywords={metaKeywords}
        setMetaKeywords={setMetaKeywords}
        metaDescription={metaDescription}
        setMetaDescription={setMetaDescription}
        isValid={validationSchema}
        setForm={setForm}
        setParentID={Events.setParentID}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        isClicked={isClicked}
        valueLang={valueLang}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
