import React, { Fragment, useContext } from "react"

import * as Contexts from "../../../../contexts"
import * as Table from "../../../../components/Table"

import { config, Translater } from "../../../../config"
import { ITrackProps } from "./types"
import { useHistory } from "react-router-dom"
import { useHttp } from "../../../../hooks/http.hook"
import moment from "moment"

const Track: React.FC<ITrackProps> = ({ data, nesting, reloadHandler }) => {
  const history = useHistory()

  const { access } = useContext(Contexts.UserContext)
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const Events = {
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
      window.location.reload()
    },
    editHandler: () => {
      history.push(`/booking/${data._id}`)
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/booking/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Fragment>
      <Table.Track type="booking">
        <Table.Cell text={(nesting + 1).toString()} />

        <Table.Cell text={data.guests + ""} />

        <Table.Cell text={data.phone} />

        <Table.Cell
          text={moment(data.date)
            .utcOffset("+0000")
            .format("DD.MM, YYYY HH:mm")}
          modalContent={
            <Table.ModalContent
              text={moment(data.date)
                .utcOffset("+0000")
                .format("DD.MM, YYYY HH:mm")}
            />
          }
        />

        <Table.Cell
          date={new Date(data.createdAt as string)}
          format="DD.MM.YYYY, HH:mm"
          modalContent={
            <Table.ModalContent
              text={moment(data.createdAt)
                .utcOffset("+0000")
                .format("DD.MM, YYYYTHH:mm")}
            />
          }
        />

        <Table.Cell text={data.restaurant.title[0].value} />

        <Table.Cell
          clickHandler={() =>
            data?.concert?._id
              ? history.push(`/libraria/libraria/${data?.concert?._id}`)
              : ""
          }
          text={data.concert?.title[0]?.value ?? "-"}
          modalContent={data.concert?.title[0]?.value ?? "-"}
        />

        {data.concert?.title[0]?.value && data?.paymentStatus ? (
          <Table.Cell
            text={
              Translater.TableTitles.statusTypes[data.paymentStatus][
                language.slug
              ] ?? "-"
            }
            modalContent={
              Translater.TableTitles.statusTypes[data.paymentStatus][
                language.slug
              ] ?? "-"
            }
          />
        ) : (
          <Table.Cell text={"-"} />
        )}

        <Table.Cell
          deleteButton
          type="control"
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>
    </Fragment>
  )
}

export default Track
