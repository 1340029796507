import React from 'react'
import { ITextareaProps } from './types'

const Textarea: React.FC<ITextareaProps> = ({
  name,
  label,
  value,
  placeholder,
  isValid = true,
  maxLength,
  textareaHandler,
  pressHandler = () => {},
}) => {
  return (
    <div className='textarea-wrapper'>
      {label ? <h6 className='input-label'>{label}</h6> : null}

      <textarea
        className={`textarea ${!isValid && 'invalid'}`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={textareaHandler}
        onKeyPress={pressHandler}
        maxLength={maxLength}
      />
    </div>
  )
}

export default Textarea
