const changeArrayItemPosition = (idx: number, old: number, array: any[]): any[] => {
    if (idx < array.length && idx >= 0) {
        const Arr: any[] = [];

        for (let item of array) {
            Arr.push(item);
        };

        Arr[idx] = array[old]
        Arr[old] = array[idx]

        return Arr;
    };
    return array;
};

export default changeArrayItemPosition