import React, { useContext, useEffect, useState } from "react"
import { IItemProps } from "./types"
import Trash from "../../assets/icons/delete.png"
import up from "../../assets/images/up.png"
import down from "../../assets/images/down.png"
import { Translater } from "../../config"
import * as Contexts from "../../contexts"

const Item: React.FC<IItemProps> = ({
  fields,
  data,
  idx,
  deleteHandler,
  deleteField,
  slugs = [],
  notDel = false,
  arrows = false,
  arrowsHandler = (val: number) => undefined,
}) => {
  const { language } = useContext(Contexts.LanguageContext)

  const [options, setOptions] = useState([])

  const changePositionHandler = (e: any) => {
    arrowsHandler(e.target.alt == "up" ? idx - 1 : idx + 1, idx)
  }

  useEffect(() => {
    setOptions(
      fields
        ?.map((field) =>
          field == "options"
            ? data[field]?.map((item: any) => {
                return (
                  <div>
                    {!item?.default
                      ? `${
                          item?.name +
                          " - " +
                          `${item?.price}грн` +
                          " - " +
                          `${item?.weight}г` +
                          `${item?.default ? " - " : ""}` +
                          `${
                            item?.default
                              ? `${
                                  Translater.TableTitles.defaultVariation[
                                    language.slug
                                  ]
                                }`
                              : ""
                          }`
                        }
                    `
                      : `${
                          item?.name +
                          " - " +
                          `${
                            item?.default
                              ? `${
                                  Translater.TableTitles.defaultVariation[
                                    language.slug
                                  ]
                                }`
                              : ""
                          }`
                        }
                    `}
                  </div>
                )
              })
            : ""
        )
        ?.filter((item) => item)[0]
    )
  }, [data])

  return (
    <>
      <div key={idx} className="page-item">
        №{idx + 1} -{" "}
        {fields.map((i, idx) => {
          return `${!!data[i] && i == "name" ? data[i] : ""}`
        })}
        {arrows &&
          //@ts-ignore
          arrowsHandler && (
            <div className="arrows">
              <img src={up} alt="up" onClick={changePositionHandler} />
              <img src={down} alt="down" onClick={changePositionHandler} />
            </div>
          )}
        {!notDel && (
          <a
            style={{ cursor: "pointer" }}
            onClick={() => deleteHandler(data && data[deleteField])}
          >
            <img className="trash-icon" src={Trash} />
          </a>
        )}
      </div>
      {options?.length && options?.map((item) => item)}
    </>
  )
}

export default Item
