import React, {
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import * as Contexts from "../../../../contexts";
import * as Buttons from "../../../../components/Buttons";
import * as Form from "../../../../components/Form";
import * as Page from "../../../../components/Page";
import * as Utils from "../../../../utils";

import { Translater, config } from "../../../../config";
import { SHiddenOptions } from "../../../../components/Form/static";
import { IItems, IVariation, TTranslations } from "../../../../types/items";
import { TVariationsProps } from "./types";
import { useHttp } from "../../../../hooks";
import { Checkbox } from "antd";
import { Modal } from "../../../../components/NewModal";
import { colors } from "@material-ui/core";
import { getStringFromTranslationsArr } from "../../../../helpers";

import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const Variations: FC<TVariationsProps> = ({
  form,
  data,
  stringifier,
  valueLang,
  setForm,
  selectHandler,
  inputHandler,
}) => {
  const { language } = useContext(Contexts.LanguageContext);
  const { access } = useContext(Contexts.UserContext);
  const { token } = useContext(Contexts.AuthContext);
  const { request } = useHttp();

  const [vars, setVars] = useState<IVariation>({
    title: [{ value: "", lang: valueLang }],
    items: [
      {
        name: [{ value: "", lang: valueLang }],
        price: "",
        weight: "",
        default: false,
      },
    ],
  });
  const [option, setOption] = useState<Partial<IVariation>>({
    items: [
      {
        name: [{ value: "", lang: valueLang }],
        price: "",
        weight: "",
        default: false,
      },
    ],
  });
  const weightOptions = [
    {
      label: Translater.Select.gramms[language.slug],
      value: Translater.Select.gramms[language.slug],
    },
    {
      label: Translater.Select.ml[language.slug],
      value: Translater.Select.ml[language.slug],
    },
  ];

  const [isOption, setIsOption] = useState<boolean>(false);
  const [defaultVariation, setDefaultVariation] = useState<boolean>(false);
  const [onClick, setOnClick] = useState<boolean>(false);
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const variationValidation = useMemo(() => {
    if (form.isVariated == "true") {
      return vars.items.filter((i) => i.name.length > 1).length > 0;
    }
    return true;
  }, [form?.isVariated, form?.variations, vars]);

  const arrowsHandler = (idx: number, old: number) => {
    const variations = Utils.changeArrayItemPosition(idx, old, form.variations);
    setForm && setForm({ ...form, variations });
  };

  const deleteVar = async (variation: string) => {
    const varData = form.variations.find(
      (item) =>
        item.title
          .map((i) => {
            if (i.lang === language.slug) {
              return i.value;
            }
          })
          .filter((item) => item)[0] == variation
    );

    await fetch(`${config.API}/variations/${varData?._id}`, {
      method: "DELETE",
      body: null,
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    });

    window.location.reload();
  };

  const addOption = () => {
    ((option.items &&
      option.items.filter((item) => item.default).length == 0 &&
      defaultVariation) ||
      !defaultVariation) &&
      setOnClick(true);
  };

  const addDefaultOption = () => {
    ((option.items &&
      option.items.filter((item) => item.default).length == 0) ||
      !defaultVariation) &&
      defaultVariation &&
      onClick &&
      addVarOption(true);
  };

  const addVarOption = (primaryVariation: boolean) => {
    if (!variationValidation) {
      toogleIsAlertOpen(true);
      setOnClick(false);
      setDefaultVariation(false);
      return;
    }

    if (
      !(
        vars.items[0].name &&
        ((vars.items[0].price && vars.items[0].weight) || primaryVariation)
      )
    ) {
      return;
    }

    if (!form.variations.length) {
      setForm({
        ...form,
        variations: [
          {
            ...vars,
            items: [
              {
                ...vars.items[0],
                default: primaryVariation,
              },
            ],
          },
        ],
      });
    }

    !primaryVariation &&
      setOption({
        items: option.items
          ?.concat([
            {
              ...vars.items[0],
              default: primaryVariation,
            },
          ])
          .filter((item) => item.name[0].value.length),
      });
    if (
      option.items &&
      primaryVariation &&
      option.items?.filter((item) => item.default)?.length <= 1
    ) {
      setOption({
        items: option.items
          ?.concat([
            {
              price: "0",
              weight: "0",
              name: vars.items[0].name,
              default: primaryVariation,
            },
          ])
          .filter((item) => item.name[0].value.length),
      });
    }
    // primaryVariation &&

    setVars({
      ...vars,
      items: [
        {
          name: [{ value: "", lang: "" }],
          price: "",
          weight: "",
          default: false,
        },
      ],
    });

    setIsOption(false);
    setOnClick(false);
  };

  const addVar = async () => {
    if (!(vars.title[0].value && option?.items)) {
      return;
    }

    const optionLength = option.items?.filter(
      (item) => item.name[0].value
    ).length;

    const response: IVariation = await request(
      `${config.API}/variations`,
      "POST",
      {
        ...vars,
        items: optionLength
          ? option.items?.map((item) => ({
              ...item,
              price: +item?.price,
              weight: +item?.weight,
            }))
          : vars.items?.map((item) => ({
              ...item,
              price: +item?.price,
              weight: +item?.weight,
            })),
      },
      {
        Authorization: `Bearer ${token as string}`,
      }
    );

    setForm({
      ...form,
      variations: [...form.variations, response].filter((item) => item._id),
    });
    setVars({
      title: [{ value: "", lang: "" }],
      items: [
        {
          name: [{ value: "", lang: "" }],
          price: "",
          weight: "",
          default: false,
        },
      ],
    });
    setOption({
      items: [
        {
          name: [{ value: "", lang: "" }],
          price: "",
          weight: "",
          default: false,
        },
      ],
    });
  };

  const varsHandler = (e: SyntheticEvent) => {
    const name = (e.target as HTMLInputElement).name;
    const value = (e.target as HTMLInputElement).value;

    if (name === "variationName") {
      const isTitleInArr = vars.title.find((item) => item.lang == valueLang)
        ? true
        : false;

      if (isTitleInArr) {
        const title = vars.title
          .map((item) => {
            if (item.lang == valueLang) {
              return { value: value, lang: valueLang };
            }
            return item;
          })
          .filter((item) => item.value);

        setVars({
          ...vars,
          title: title,
          items: vars.items,
        });

        return;
      }

      const title = vars.title
        .concat({
          value: value,
          lang: valueLang,
        })
        .filter((item) => item.value);

      setVars({
        ...vars,
        title: title,
        items: vars.items,
      });

      return;
    }

    if (name === "optionName") {
      const isTitleInArr = vars.items[0].name.find(
        (item) => item.lang == valueLang
      )
        ? true
        : false;

      if (isTitleInArr) {
        const name = vars.items[0].name
          .map((item) => {
            if (item.lang == valueLang) {
              return { value: value, lang: valueLang };
            }
            return item;
          })
          .filter((item) => item.value);

        setVars({
          ...vars,
          title: vars.title,
          items: [
            {
              name: name,
              price: vars.items[0]?.price,
              weight: vars.items[0]?.weight,
              default: defaultVariation,
            },
          ],
        });

        return;
      }

      const name = vars.items[0].name
        .concat({
          value: value,
          lang: valueLang,
        })
        .filter((item) => item.value);

      setVars({
        ...vars,
        title: vars.title,
        items: [
          {
            name: name,
            price: vars.items[0]?.price,
            weight: vars.items[0]?.weight,
            default: defaultVariation,
          },
        ],
      });

      return;
    }

    if (name === "priceChange") {
      setVars({
        ...vars,
        title: vars.title,
        items: [
          {
            name: vars.items[0]?.name,
            price: value,
            weight: vars.items[0]?.weight,
            default: defaultVariation,
          },
        ],
      });

      return;
    }

    if (name === "weightChange") {
      setVars({
        ...vars,
        title: vars.title,
        items: [
          {
            name: vars.items[0]?.name,
            price: vars.items[0]?.price,
            weight: value,
            default: defaultVariation,
          },
        ],
      });

      return;
    }
  };

  useEffect(() => {
    if (option.items && option?.items[0]?.name[0]?.value)
      form?.variations?.map((item) => {
        if (vars?.title[0]?.value === item?.title[0]?.value) {
          setForm &&
            setForm({
              ...form,
              variations: form.variations
                ?.concat({
                  ...item,
                  items: option?.items as IItems[],
                })
                ?.reverse()
                ?.filter((value, index, self) => {
                  return (
                    self?.findIndex(
                      (v) => v?.title[0]?.value === value?.title[0]?.value
                    ) === index
                  );
                }),
            });

          return;
        }

        setForm &&
          setForm({
            ...form,
            variations: form.variations
              .concat({
                ...vars,
                items: option?.items as IItems[],
              })
              ?.reverse()
              ?.filter((value, index, self) => {
                return (
                  self?.findIndex(
                    (v) => v?.title[0]?.value === value?.title[0]?.value
                  ) === index
                );
              }),
          });

        return;
      });

    setDefaultVariation(false);
  }, [option]);

  useEffect(() => {
    setVars({
      ...vars,
      items: [
        {
          ...vars.items[0],
          default: defaultVariation,
        },
      ],
    });
  }, [defaultVariation]);

  return (
    <React.Fragment>
      <>
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.weight[language.slug]}
        >
          <Form.Input
            name="weight"
            placeholder={Translater.Placeholders.minTwo[language.slug]}
            value={form.weight}
            inputHandler={inputHandler}
          />
          <Form.Select
            name="measurement"
            value={getStringFromTranslationsArr(
              form?.measurement as TTranslations,
              language.slug
            )}
            options={weightOptions}
            selectHandler={selectHandler}
          />
        </Form.LabelField>

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.price[language.slug]}
        >
          <Form.Input
            name="price"
            value={form.price}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      </>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hasVariations[language.slug]}
      >
        <Form.Select
          name="isVariated"
          options={SHiddenOptions}
          value={form.isVariated as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      {form.isVariated == "true" && (
        <>
          {form?.variations?.map((i, idx) => (
            <Page.VariationItem
              deleteField="name"
              idx={idx}
              key={idx}
              data={{
                name: i?.title
                  ?.map((item) => {
                    if (item.lang === valueLang) {
                      return item.value;
                    }
                  })
                  .filter((item) => item)[0],
                options: i?.items?.map((item) => ({
                  ...item,
                  name: item?.name
                    ?.map((item) => {
                      if (item.lang === valueLang) {
                        return item.value;
                      }
                    })
                    ?.filter((item) => item)[0],
                })),
              }}
              deleteHandler={deleteVar}
              fields={["name", "options"]}
              arrows
              arrowsHandler={arrowsHandler}
            />
          ))}

          <Form.LabelField
            fontSize="small"
            label={Translater.Variants.property[language.slug]}
          >
            <Form.Input
              key={stringifier}
              inputHandler={varsHandler}
              value={getStringFromTranslationsArr(vars?.title, valueLang)}
              name="variationName"
            />
          </Form.LabelField>

          {!isOption && (
            <Buttons.DefaultButton
              title={Translater.Buttons.addOption[language.slug]}
              buttonHandler={() => setIsOption(!isOption)}
            />
          )}

          {isOption && (
            <>
              <Form.LabelField
                fontSize="small"
                label={Translater.Variants.optionName[language.slug]}
              >
                <Form.Input
                  key={stringifier}
                  inputHandler={varsHandler}
                  value={getStringFromTranslationsArr(
                    vars.items[0]?.name,
                    valueLang
                  )}
                  name="optionName"
                />
              </Form.LabelField>

              {!defaultVariation && (
                <Form.LabelField
                  fontSize="small"
                  label={Translater.TableTitles.weight[language.slug]}
                >
                  <Form.Input
                    inputHandler={varsHandler}
                    value={vars.items[0]?.weight + ""}
                    name="weightChange"
                  />
                </Form.LabelField>
              )}

              {!defaultVariation && (
                <Form.LabelField
                  fontSize="small"
                  label={Translater.TableTitles.price[language.slug]}
                >
                  <Form.Input
                    inputHandler={varsHandler}
                    value={vars.items[0]?.price + ""}
                    name="priceChange"
                  />
                </Form.LabelField>
              )}

              <Form.LabelField
                fontSize="small"
                label={Translater.TableTitles.defaultVariation[language.slug]}
              >
                <Checkbox
                  value={defaultVariation}
                  name="default"
                  onChange={() => {
                    setDefaultVariation(!defaultVariation);
                  }}
                />
              </Form.LabelField>

              <>
                <Buttons.DefaultButton
                  title={Translater.Buttons.addOption[language.slug]}
                  buttonHandler={addOption}
                />
              </>
            </>
          )}
          {form.variations.length > 0 && (
            <Buttons.DefaultButton
              title={Translater.Buttons.add[language.slug]}
              buttonHandler={addVar}
            />
          )}
        </>
      )}

      {addDefaultOption()}

      {((option.items &&
        option.items.filter((item) => item.default).length == 0) ||
        !defaultVariation) &&
        onClick &&
        !defaultVariation && (
          <Modal padding="25px" background={colors.common.white}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <p>{`Необхідно обрати базову варіацію`}</p>
                <Buttons.DefaultButton
                  padding="15px"
                  title="Зробити базовой варіаціей"
                  buttonHandler={() => {
                    setDefaultVariation(true);
                    addVarOption(true);
                  }}
                />
                <Buttons.DefaultButton
                  padding="15px"
                  title="Зробити базовой іншу варіацію"
                  buttonHandler={() => {
                    setDefaultVariation(false);
                    addVarOption(false);
                  }}
                />
              </div>
            </div>
          </Modal>
        )}

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          <p>{Translater.ErrorVariations[language.slug]}</p>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
