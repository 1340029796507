import React, { useState, createRef, Dispatch } from "react"
import Cropper, { ReactCropperElement } from "react-cropper"
import "cropperjs/dist/cropper.css"
import { useDebounceEffect } from "./useDebounceEffect"

export const ImageCropper: React.FC<{ setPhoto: Dispatch<File> }> = ({
  setPhoto,
}) => {
  const [image, setImage] = useState("")
  const cropperRef = createRef<ReactCropperElement>()

  const onChange = (e: any) => {
    e.preventDefault()
    let files

    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const reader = new FileReader()

    reader.onload = () => {
      setImage(reader.result as any)
    }
    reader.readAsDataURL(files[0])
  }

  useDebounceEffect(
    () => {
      if (cropperRef.current && cropperRef.current.cropper.getCroppedCanvas()) {
        cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "image.jpg", { type: blob?.type })
            setPhoto(file)
          }
        })
      }
    },
    500,
    [cropperRef]
  )

  return (
    <div>
      <div style={{ width: "100%" }}>
        <input type="file" onChange={onChange} />
        <Cropper
          ref={cropperRef}
          style={{ height: image ? "350px" : "0", width: "100%" }}
          data={{ width: 251.6, height: 210 }}
          initialAspectRatio={1.2583}
          aspectRatio={1.2583}
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          cropBoxResizable={false}
          dragMode="move"
        />
      </div>
    </div>
  )
}
