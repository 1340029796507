import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import * as Components from "./components";
import * as Contexts from "../../contexts";
import * as Buttons from "../../components/Buttons";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import { Switch } from "antd";
import { TCoordinates, TFormTypes } from "./types";
import { IRestaurant, TTranslations } from "../../types/items";
import { useParams, useHistory } from "react-router-dom";
import { config, Translater } from "../../config";
import { useHttp } from "../../hooks";
import { useValidation } from "../../hooks";
import { TabLanguage } from "../../components/TabLanguage";
import { getStringFromTranslationsArr } from "../../helpers";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const history = useHistory();
  const { id } = useParams() as any;
  const { loading, request } = useHttp();

  const [primary, setPrimary] = useState<IRestaurant | null>(null);
  const [headerPhoto, setHeaderPhoto] = useState<File | null | any>(null);
  const [photo, setPhoto] = useState<File | null>(null);
  const [pdf, setPdf] = useState<File | null>(null);
  const [startDate, setStartDate] = useState<Date | string>(new Date());
  const [endDate, setEndDate] = useState<Date | string>(new Date());
  const [valueLang, setValueLang] = useState<string>(language.slug);
  const [startDateDelivery, setStartDateDelivery] = useState<Date | string>(
    new Date()
  );
  const [metaKeywords, setMetaKeywords] = useState<string[]>([]);
  const [metaDescription, setMetaDescription] = useState<string>("");
  const [endDateDelivery, setEndDateDelivery] = useState<Date | string>(
    new Date()
  );
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    city: "",
    login: "",
    title: [{ value: "", lang: "" }],
    password: "",
    email: "",
    phone: "",
    facebook: "",
    instagram: "",
    telegram: "",
    headerColor: "",
    order: "",
    enableBasket: false,
    show: false,
    hasDelivery: "",
    minimalOrder: 0,
  });
  const [value, setValue] = useState<{ value: string; lang: string }[]>([]);
  const [coordinates, setCoordinates] = useState<TCoordinates>({
    lat: "0",
    lon: "0",
  });
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  function convertTimeStringToDate(timeString: string) {
    // Створюємо об'єкт moment з рядка часу
    const timeMoment = moment(timeString, "HH:mm");

    // Конвертуємо об'єкт moment назад в нативний об'єкт Date
    return timeMoment.toDate();
  }
  const schema = useMemo<any>(
    () => ({
      title: {
        condition:
          form.title
            .map((item: any) => {
              if (!item.value) {
                return false;
              }
              return true;
            })
            .filter((item: any) => item).length == 2,
        error: `
          ${Translater.ErrorLanguage[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      photo: {
        condition: true,
        error: `
        ${Translater.ErrorPhoto[language.slug]}:
        ${Translater.TableTitles.phone[language.slug]}`,
      },
      phone: {
        condition: !!form.phone,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.phone[language.slug]}`,
      },
      // email: {
      //   condition: !!form.email,
      //   error: `
      //     ${Translater.ErrorLength[language.slug]}:
      //     ${Translater.TableTitles.email[language.slug]}`,
      // },
      value: {
        condition: !!value,
        error: `
          ${Translater.ErrorAddress[language.slug]}:
          ${Translater.TableTitles.address[language.slug]}`,
      },
      startDate: {
        condition:
          moment(startDate).format("HH:mm") <= moment(endDate).format("HH:mm"),
        error: `${Translater.ErrorTimeStart[language.slug]}`,
      },
      endDate: {
        condition:
          moment(startDate).format("HH:mm") <= moment(endDate).format("HH:mm"),
        error: `${Translater.ErrorTimeEnd[language.slug]}`,
      },
    }),
    [form, language, value, startDate, endDate]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      if (e.target.name === "title") {
        const isTitleInArr = form.title.find(
          (item: any) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const title = form.title
            .map((item: any) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item: any) => item.value);

          setForm({
            ...form,
            title: title as { value: string; lang: string }[],
          });

          return;
        }

        const title = form.title
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item: any) => item.value);

        setForm({
          ...form,
          title: title as { value: string; lang: string }[],
        });

        return;
      }
      if (e.target.name === "pdf") {
        setPdf(e.target?.files?.length ? e.target?.files[0] : null);
        return;
      }

      if (e.target.name === "photo") {
        setPhoto(e.target?.files?.length ? e.target?.files[0] : null);
        return;
      } else setForm({ ...form, [name]: value });
    },
    inputFileHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setHeaderPhoto(e.target?.files?.length ? e.target?.files[0] : null);
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    saveHandler: async () => {
      try {
        setIsClicked(true);
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant = await request(
          `${config.API}/restaurants/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          setPrimary(response);
        }

        const newForm = {
          ...form,
          ...(response.title ? { title: response.title } : {}),
          ...(response?.contacts?.email
            ? { email: response?.contacts?.email }
            : {}),
          ...(response?.contacts?.phone
            ? { phone: response?.contacts?.phone }
            : {}),
          ...(response?.contacts?.social?.facebook
            ? { facebook: response?.contacts?.social?.facebook }
            : {}),
          ...(response?.contacts?.social?.telegram
            ? { telegram: response?.contacts?.social?.telegram }
            : {}),
          ...(response?.contacts?.social?.instagram
            ? { instagram: response?.contacts?.social?.instagram }
            : {}),
          ...(response?.headerColor
            ? { headerColor: response?.headerColor }
            : {}),
          ...(response?.hasDelivery
            ? { hasDelivery: response?.hasDelivery }
            : {}),
          enableBasket: !!response.enableBasket,
          show: !!response.show,
          minimalOrder: response.minimalOrder || 0,
        };
        setCoordinates({
          lon: response.location.coordinates[0] + "",
          lat: response.location.coordinates[0] + "",
        });
        setStartDate(
          `${
            convertTimeStringToDate(response?.workingTime?.start.toString()) ??
            new Date()
          }`
        );
        setStartDateDelivery(
          `${
            convertTimeStringToDate(response?.deliveryTime?.start.toString()) ??
            new Date()
          }`
        );
        setEndDate(
          `${
            convertTimeStringToDate(response?.workingTime?.end.toString()) ??
            new Date()
          }`
        );
        setEndDateDelivery(
          `${
            convertTimeStringToDate(response?.deliveryTime?.end.toString()) ??
            new Date()
          }`
        );
        setValue(response?.address);
        setForm(newForm);
        response.metaDescription &&
          setMetaDescription(response.metaDescription);
        response.metaKeywords && setMetaKeywords(response.metaKeywords);
      } catch (e) {}
    },
    Save: async () => {
      try {
        const {
          title,
          email,
          phone,
          facebook,
          instagram,
          telegram,
          enableBasket,
          show,
          minimalOrder,
        } = form;

        const contacts: IRestaurant["contacts"] = {
          phone,
          email,
          social: {
            facebook,
            instagram,
            telegram,
          },
        };
        const data: FormData = new FormData();

        data.append("contacts", JSON.stringify(contacts));
        pdf && data.append("offlineMenu", pdf as Blob);
        data.append("address", JSON.stringify(value));
        data.append(
          "workingTime",
          JSON.stringify({
            start: moment(startDate).format("HH:mm"),
            end: moment(endDate).format("HH:mm"),
          })
        );
        data.append(
          "deliveryTime",
          JSON.stringify({
            start: moment(startDateDelivery).format("HH:mm"),
            end: moment(endDateDelivery).format("HH:mm"),
          })
        );
        data.append("show", show + "");
        data.append("enableBasket", enableBasket + "");
        data.append("minimalOrder", minimalOrder);
        photo && data.append("mainImage", photo as Blob);
        coordinates &&
          data.append(
            "location",
            JSON.stringify({
              coordinates: [+coordinates.lon, +coordinates.lat],
              type: "Point",
            })
          );
        data.append("title", JSON.stringify(form?.title));
        data.append("hasDelivery", form?.hasDelivery);

        data.append("metaDescription", metaDescription);
        if (metaKeywords.length) {
          metaKeywords.map((el) => data.append("metaKeywords[]", el));
        }

        const response = await request(
          `${config.API}/restaurants/${id}`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) history.push("/restaurants");
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/restaurants/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/restaurants");
      } catch (e) {
        console.log(e);
      }
    },
  };
  useEffect(() => {
    Callbacks.Fetch();
  }, [id]);

  return (
    <Page.Wrapper
      title={getStringFromTranslationsArr(
        primary?.title as TTranslations,
        language.slug
      )}
    >
      <Page.Header
        backButtonTitle={Translater.RestaurantsDetailPage.title[language.slug]}
        backButtonLink="/restaurants"
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.restaurants?.change ? Events.saveHandler : false}
        // deleteHandler={
        //   access.restaurants?.rule === "change" ? Events.deleteHandler : false
        // }
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />
      <div style={{ margin: "20px 0" }}>
        <p>Показувати ресторан</p>
        <Switch
          checked={!!form.show as boolean}
          onChange={() =>
            setForm((prev: any) => ({
              ...prev,
              show: !prev.show,
            }))
          }
        />
        <p>Додавати в корзину</p>
        <Switch
          checked={!!form.enableBasket}
          onChange={() =>
            setForm((prev: any) => ({
              ...prev,
              enableBasket: !prev.enableBasket,
            }))
          }
        />
      </div>
      <Components.ConfigBlock
        form={form}
        metaKeywords={metaKeywords}
        setMetaKeywords={setMetaKeywords}
        metaDescription={metaDescription}
        setMetaDescription={setMetaDescription}
        photo={photo}
        data={primary ?? undefined}
        address={value}
        headerPhoto={headerPhoto}
        setForm={setForm as any}
        isClicked={isClicked}
        inputFileHandler={Events.inputFileHandler}
        inputHandler={Events.inputHandler}
        valueLang={valueLang}
        setPhoto={setPhoto}
      />

      <Components.ContactsBlock
        form={form}
        setForm={setForm}
        isClicked={isClicked}
        setAddress={setValue}
        startDate={startDate}
        startDateDelivery={startDateDelivery}
        endDateDelivery={endDateDelivery}
        setStartDate={setStartDate}
        setStartDateDelivery={setStartDateDelivery}
        setEndDateDelivery={setEndDateDelivery}
        endDate={endDate}
        setEndDate={setEndDate}
        address={value}
        valueLang={valueLang}
        setCoordinates={setCoordinates}
        isValid={validationSchema}
        inputHandler={Events.inputHandler}
      />

      <Form.DateBlock
        updated={primary?.updatedAt as string}
        created={primary?.createdAt as string}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
