import React, { Fragment, useState, useEffect, useContext } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";
import * as Page from "../../../components/Page";
import * as Utils from "../../../utils";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { SHiddenOptions } from "../../../components/Form/static";
import {
  IAdditions,
  ICategory,
  IProduct,
  IRestaurant,
} from "../../../types/items";
import { IConfigBlockProps } from "./types";
import { DefaultButton } from "../../../components/Buttons";
import { InputWithTags } from "../../../components/InputWithTags";
import { Variations } from "./Variations";
import { getStringFromTranslationsArr } from "../../../helpers";
import { ImageCropper } from "./ImageCropper";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  showPhoto = false,
  setCategory,
  inputHandler,
  selectHandler,
  setForm,
  textareaHandler,
  isClicked,
  metaKeywords,
  setMetaKeywords,
  metaDescription,
  setMetaDescription,
  valueLang,
  setPhoto,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const [showValue, setShowValue] = useState<boolean>(false);
  const [, setInputValue] = useState<string>("");
  const [, setInputRestaurantValue] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<ICategory[]>([]);
  const [restaurantOptions, setRestaurantOptions] = useState<IRestaurant[]>([]);
  const [children, setChildren] = useState<any[]>([]);
  const [, setCategorySelectValue] = useState<any>({
    label: "",
    value: "",
  });
  const [adds, setAdds] = useState<IAdditions[]>([]);
  const [ad, setAd] = useState<any>({});
  const [rest, setRest] = useState<any>({});
  const [searchSelAds, setSearchSelAds] = useState<string>("");

  const Callbacks = {
    loadAdditionsById: async (
      id = form.restaurant[0] ? form.restaurant[0] : "0"
    ) => {
      try {
        const adds = await request(
          `${config.API}/addition?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        setAdds(adds);
      } catch (e) {
        console.log(e);
      }
    },
  };

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value);
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value);
    },
    loadChildrenOptions: async () => {
      try {
        if (!!rest) {
          const response: IProduct[] = await request(
            `${config.API}/products?perPage=1000`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            }
          );

          setChildren(response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (response && form._id) {
          setForm && setForm({ ...form, restaurant: response[0]._id });
        }
        setRestaurantOptions(response);
      } catch (e) {}
    },
    loadCategoriesOptions: async () => {
      try {
        const response: ICategory[] = await request(
          `${config.API}/categories?perPage=50`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        setCategoryOptions(response);
      } catch (e) {
        console.log(e);
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option);
      setCategory(option.value);
    },
    buttonHandler: () => {
      setShowValue(!showValue);
    },

    arrowsHandler: (idx: number, old: number) => {
      const variations = Utils.changeArrayItemPosition(
        idx,
        old,
        form.variations
      );
      setForm && setForm({ ...form, variations });
    },
    selectHandler: (e: any) => {
      console.log("E", e.target.value);
    },
  };

  useEffect(() => {
    Events.loadRestaurantOptions();
  }, []);

  useEffect(() => {
    Events.loadChildrenOptions();
  }, [rest]);

  useEffect(() => {
    Events.loadCategoriesOptions();
  }, []);

  useEffect(() => {
    if (form.restaurant) {
      Callbacks.loadAdditionsById();
    } else {
      Callbacks.loadAdditionsById(rest._id);
    }
  }, [rest, form.restaurant]);

  const adsSelectHandler = (ad: any) => {
    if (ad) {
      const addition = adds.find((item) => item._id === ad.value);
      setAd(addition);
    }
  };

  const addOptionHandler = () => {
    if (Object.keys(ad).length) {
      const additions = form.additions && [
        ...form.additions.filter((i) => i._id != ad._id),
        ad,
      ];
      setForm && setForm({ ...form, additions });
    }
  };

  const deleteAd = (id: string) => {
    console.log(id);
    if (id != null) {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i._id !== id),
        });
    } else {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i != null),
        });
    }
  };

  useEffect(() => {
    if (typeof form.isVariated == "boolean") {
      if (form.isVariated) {
        setForm && setForm({ ...form, isVariated: "true" });
      } else {
        setForm && setForm({ ...form, isVariated: "false" });
      }
    }
  });

  const [stringifier, setStringifier] = useState<string>("");

  useEffect(() => {
    setStringifier(JSON.stringify(form?.additions));
  }, [form?.additions]);

  useEffect(() => {
    setStringifier(JSON.stringify(form?.variations));
  }, [form?.variations]);

  useEffect(() => {
    setStringifier(JSON.stringify(form?.restaurant?._id));
  }, [form?.restaurant?._id]);

  useEffect(() => {
    setStringifier(JSON.stringify(valueLang));
  }, [valueLang]);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          key={stringifier}
          name="name"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.name.length > 0 : true}
          value={getStringFromTranslationsArr(form.name, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
      >
        <Form.Textarea
          key={stringifier}
          name="description"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.description.length > 0 : true}
          value={getStringFromTranslationsArr(form.description, valueLang)}
          textareaHandler={textareaHandler}
        />
      </Form.LabelField>

      <Variations
        form={form}
        setForm={setForm}
        data={data as IProduct}
        stringifier={stringifier}
        valueLang={valueLang}
        selectHandler={selectHandler}
        inputHandler={inputHandler}
      />

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hidden[language.slug]}
      >
        <Form.Select
          name="hidden"
          options={SHiddenOptions}
          value={form.hidden as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.novelty[language.slug]}
      >
        <Form.Select
          name="novelty"
          options={SHiddenOptions}
          value={form.novelty as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurants[language.slug]}
      >
        <Fragment>
          <Page.SearchSelect
            key={stringifier}
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField="title"
            data={restaurantOptions.map((item) => ({
              label: getStringFromTranslationsArr(item?.title, valueLang),
              value: item?._id,
            }))}
            setSwitch={(restaurant) => {
              setForm({
                ...form,
                restaurant: restaurantOptions
                  .map((rest) => {
                    if (rest._id == restaurant.value) return rest;
                  })
                  .filter((rest) => rest?._id)[0] as IRestaurant,
              });
            }}
            value={{
              label: getStringFromTranslationsArr(
                form.restaurant?.title,
                valueLang
              ),
              value: form.restaurant?._id,
            }}
          />
        </Fragment>
      </Form.LabelField>

      {form?.restaurant?._id && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.category[language.slug]}
        >
          <Fragment>
            <>
              <Page.SearchSelect
                key={JSON.stringify(form?.restaurant?._id)}
                placeholder={Translater.Placeholders.category[language.slug]}
                data={categoryOptions
                  .filter((item) =>
                    (item?.restaurant as any)?.find(
                      (item: any) => item._id == form?.restaurant?._id
                    )
                  )
                  .map((item) => ({
                    label: getStringFromTranslationsArr(
                      item?.title,
                      language.slug
                    ),
                    value: item?._id,
                  }))}
                searchField="title"
                setSwitch={(category) => {
                  setForm({
                    ...form,
                    category: categoryOptions
                      .map((cat) => {
                        if (cat?._id == category?.value) return cat;
                      })
                      .filter((cat) => cat?._id)[0] as ICategory,
                  });
                }}
                value={
                  form?.category?.restaurant
                    ?.map((i: any) => i == form?.restaurant?._id)
                    ?.filter((item: any) => item)[0]
                    ? {
                        label: getStringFromTranslationsArr(
                          form?.category?.title,
                          language.slug
                        ),
                        value: form?.category?._id,
                      }
                    : {}
                }
              />
            </>
          </Fragment>
        </Form.LabelField>
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <ImageCropper setPhoto={setPhoto} />
      </Form.LabelField>

      {showPhoto ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.photo[language.slug]}
        >
          {data?.photo !== null ? (
            <img
              alt="Product image"
              src={`${config.imageProxy}public/${(data as any).photo}`}
              style={{ width: "100%", height: "250px" }}
            />
          ) : (
            <img alt="Product image" />
          )}
        </Form.LabelField>
      ) : null}

      {form.additions?.map((i, idx) => (
        <Page.Item
          data={{
            ...i,
            name: getStringFromTranslationsArr(i.name, language.slug),
          }}
          fields={["name", "price", "weight"]}
          idx={idx}
          key={idx}
          deleteHandler={deleteAd}
          deleteField="_id"
        />
      ))}

      {showValue && adds ? (
        <div>
          {adds && (
            <div className="adds">
              <Page.SearchSelect
                key={stringifier}
                placeholder={
                  Translater.Placeholders.searchAddition[language.slug]
                }
                data={adds
                  .filter(
                    (item) =>
                      !form.additions.map((item) => item._id).includes(item._id)
                  )

                  .filter((item) => item.restaurant == form?.restaurant?._id)

                  .map((item) => ({
                    label: getStringFromTranslationsArr(
                      item?.name,
                      language.slug
                    ),
                    value: item?._id,
                  }))}
                searchField="name"
                setSwitch={adsSelectHandler}
                value={searchSelAds}
                setValue={setSearchSelAds}
              />
              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addOptionHandler}
              />
            </div>
          )}
        </div>
      ) : null}

      <DefaultButton
        title={Translater.Buttons.addOptions[language.slug]}
        buttonHandler={Events.buttonHandler}
      />

      <InputWithTags
        tags={metaKeywords}
        setTags={setMetaKeywords}
        descriptionValue={metaDescription}
        setDescriptionValue={setMetaDescription}
      />
    </Fragment>
  );
};

export default ConfigBlock;
