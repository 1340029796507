import React, { useState, useContext, ChangeEvent, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import * as Contexts from "../../contexts";
import * as Buttons from "../../components/Buttons";
import * as Page from "../../components/Page";
import * as Components from "./components";

import { useHttp } from "../../hooks";
import { config, Translater } from "../../config";

import { IProduct, IOptions } from "../../types/items";
import { TCreateValidationSchema } from "./types";
import { useValidation } from "../../hooks";
import { TabLanguage } from "../../components/TabLanguage";

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, request } = useHttp();
  const history = useHistory();

  const [valueLang, setValueLang] = useState<string>(language.slug);
  const [form, setForm] = useState<IProduct>({
    name: [{ value: "", lang: "" }],
    price: "0",
    photo: "",
    parent: "",
    hidden: "false",
    category: "",
    restaurant: "",
    description: [{ value: "", lang: "" }],
    options: [],
    weight: "0",
    novelty: "false",
    additions: [],
    variations: [],
    isVariated: false,
    measurement: [
      { lang: "uk", value: Translater.Select.gramms.uk },
      { lang: "en", value: Translater.Select.gramms.en },
    ],
  });

  const [metaKeywords, setMetaKeywords] = useState<string[]>([]);
  const [metaDescription, setMetaDescription] = useState<string>("");
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [isClicked, setClicked] = useState(false);
  const [photo, setPhoto] = useState<File>();

  const variationValidation = useMemo(() => {
    if (form.isVariated == "true") {
      const res: boolean[] = [];

      for (let variation of form.variations) {
        res.concat(
          variation.items.filter((i) => i.name.length > 1).length ==
            variation.items.length
        );
      }

      if (!res.filter((item) => !item).length) {
        return true;
      }

      return false;
    }
    return true;
  }, [form?.isVariated, form?.variations]);

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      name: {
        condition:
          form.name
            .map((item) => {
              if (!item.value) {
                return false;
              }
              return true;
            })
            .filter((item) => item).length == 2,
        error: `
        ${Translater.ErrorLanguage[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
      // weight: {
      //   condition:
      //     (parseInt(form.weight) >= 0 &&
      //       (form.isVariated == "false" || !form.isVariated)) ||
      //     (form.isVariated && form.isVariated != "false") ||
      //     form.isVariated == "true",
      //   error: ` ${Translater.ErrorLength[language.slug]}:
      //   ${Translater.TableTitles.weight[language.slug]}`,
      // },

      // price: {
      //   condition:
      //     (+form.price > 0 &&
      //       (form.isVariated == "false" || !form.isVariated)) ||
      //     (form.isVariated && form.isVariated != "false") ||
      //     form.isVariated == "true",
      //   error: ` ${Translater.ErrorLength[language.slug]}:
      //   ${Translater.TableTitles.price[language.slug]}`,
      // },
      // description: {
      //   condition:
      //     form.description
      //       .map((item) => {
      //         if (!item.value) {
      //           return false;
      //         }
      //         return true;
      //       })
      //       .filter((item) => item).length == 2,
      //   error: ` ${Translater.ErrorLanguage[language.slug]}:
      //   ${Translater.TableTitles.description[language.slug]}`,
      // },
      category: {
        condition: !!form.category,
        error: `${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.categoriesList[language.slug]}
        `,
      },
      restaurant: {
        condition: !!form.restaurant,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
      photo: {
        condition: !!photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      variations: {
        condition:
          ((((form.isVariated && form.isVariated != "false") ||
            form.isVariated == "true") &&
            form.variations.length) ||
            form.isVariated == "false" ||
            !form.isVariated) &&
          variationValidation,
        error: Translater.ErrorVariations[language.slug],
      },
    }),
    [form, Translater, language, photo]
  );

  console.log("photo", photo);

  const { errors, validation } = useValidation(schema);

  const [optionValue, setOptionValue] = useState<any>({
    option: "",
    photo_option: "",
    price_option: "0",
    additions: "",
  });

  const [options, setOptions] = useState<IOptions[]>([]);

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;

      //@ts-ignore
      if (type === "file")
        //@ts-ignore
        setOptionValue({ ...optionValue, photo_option: e.target.files[0] });
      else {
        setOptionValue({ ...optionValue, [name]: value });
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "name") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false;

        if (isTitleInArr) {
          const name = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            name: name as { value: string; lang: string }[],
          });

          return;
        }

        const name = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          name: name as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;
      //@ts-ignore
      if (type === "file") setForm({ ...form, photo: e.target.files[0] });
      else if (type === "number") {
        if (+value >= 0) setForm({ ...form, [name]: value });
      } else setForm({ ...form, [name]: value });
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name == "weight" && +e.target.value >= 0) {
        setForm({ ...form, [e.target.name]: e.target.value });
        return;
      }

      if (e.target.name == "price" && +e.target.value >= 0) {
        setForm({ ...form, [e.target.name]: e.target.value });
        return;
      }

      setForm({ ...form, [e.target.name]: e.target.value });
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue]);
      setOptionValue({
        option: "",
        photo_option: "",
        price_option: "0",
        additions: "",
      });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      if (
        name == "measurement" &&
        value == Translater.Select.gramms[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: "uk", value: Translater.Select.gramms.uk },
            { lang: "en", value: Translater.Select.gramms.en },
          ],
        });
        return;
      }

      if (
        name == "measurement" &&
        value == Translater.Select.ml[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: "uk", value: Translater.Select.ml.uk },
            { lang: "en", value: Translater.Select.ml.en },
          ],
        });
        return;
      }

      setForm({ ...form, [e.target.name]: e.target.value });
    },
    setCategory: (category: string) => {
      setForm({ ...form, category });
    },
    saveHandler: async () => {
      try {
        setClicked(true);
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
        console.log(e);
      }
    },
  };

  const Callbacks = {
    Save: async () => {
      try {
        const data = new FormData();

        data.append("name", JSON.stringify(form.name));

        data.append("description", JSON.stringify(form.description));

        if (photo) data.append("photo", photo);
        data.append("isVariated", form.isVariated as string);

        form.price && data.append("price", form.price as string);
        data.append("hidden", form.hidden as string);
        data.append("novelty", form.novelty as string);
        data.append("category", form.category._id);
        data.append("measurement", JSON.stringify(form.measurement));
        data.append("restaurant[]", form.restaurant._id);

        if (form.parent) data.append("parent", form.parent as string);

        data.append("weight", form.weight as string);

        const addsArray: any[] = [];
        for (let ad of form.additions) {
          addsArray.push(ad._id);
        }

        data.append("metaDescription", metaDescription);
        if (metaKeywords.length) {
          metaKeywords.map((el) => data.append("metaKeywords[]", el));
        }

        for (let i = 0; i < addsArray.length; i++) {
          data.append("additions[]", JSON.stringify(addsArray[i]) as any);
        }

        for (let varData of form.variations) {
          data.append("variations[]", JSON.stringify(varData._id));
        }

        const response = await request(`${config.API}/products`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        });
        history.push("/products");
      } catch (e) {}
    },
  };

  return (
    <Page.Wrapper title={Translater.ProductsCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.ProductsCreatePage.title[language.slug]}
        backButtonLink="/products"
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Buttons.Container
        disabled={loading}
        createHandler={access.products?.change ? Events.saveHandler : false}
      />

      <Components.ConfigBlock
        metaKeywords={metaKeywords}
        setMetaKeywords={setMetaKeywords}
        metaDescription={metaDescription}
        setMetaDescription={setMetaDescription}
        form={form}
        setForm={setForm}
        optionValue={optionValue}
        options={options}
        setCategory={Events.setCategory}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
        isClicked={isClicked}
        valueLang={valueLang}
        setPhoto={setPhoto}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default CreatePage;
