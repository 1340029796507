import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  Fragment,
  useMemo,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Components from "./components";
import * as Buttons from "../../components/Buttons";

import { useHttp } from "../../hooks";
import { IProduct, IOptions, IAdditions, IVariation } from "../../types/items";
import { config, Translater } from "../../config";

import { useValidation } from "../../hooks";

import { TDetailValidationSchema } from "./types";
import { TabLanguage } from "../../components/TabLanguage";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);
  const [defaultAdditions, setDefaultAdditions] = useState<IAdditions[]>([]);

  const history = useHistory();
  const { id } = useParams() as any;
  const { loading, request } = useHttp();

  const [primary, setPrimary] = useState<IProduct | null>(null);
  const [isValid, toggleValid] = useState({
    name: true,
    category: true,
    description: true,
    weight: true,
    restaurant: true,
  });

  const [metaKeywords, setMetaKeywords] = useState<string[]>([]);
  const [valueLang, setValueLang] = useState<string>(language.slug);
  const [metaDescription, setMetaDescription] = useState<string>("");
  const [photo, setPhoto] = useState<File>();

  const [form, setForm] = useState<IProduct>({
    name: [{ value: "", lang: "" }],
    price: "",
    photo: "",
    parent: "",
    hidden: "",
    category: "",
    restaurant: "",
    description: [{ value: "", lang: "" }],
    options: [],
    weight: "",
    novelty: "false",
    additions: [],
    variations: [],
    isVariated: false,
  });
  const [optionValue, setOptionValue] = useState<any>({
    option: "",
    photo_option: "",
    price_option: "0",
    additions: "",
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const variationValidation = useMemo(() => {
    if (form.isVariated == "true") {
      return (
        form.variations.filter(
          (item) => item.items.filter((i) => i.name.length > 1).length
        ).length > 0
      );
    }
    return true;
  }, [form?.isVariated, form?.variations]);

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      name: {
        condition:
          form.name
            .map((item) => {
              if (!item.value) {
                return false;
              }
              return true;
            })
            .filter((item) => item).length == 2,
        error: `
        ${Translater.ErrorLanguage[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
      // description: {
      //   condition:
      //     form.description
      //       .map((item) => {
      //         if (!item.value) {
      //           return false
      //         }
      //         return true
      //       })
      //       .filter((item) => item).length == 2,
      //   error: ` ${Translater.ErrorLanguage[language.slug]}:
      //   ${Translater.TableTitles.description[language.slug]}`,
      // },
      category: {
        condition: !!form.category,
        error: ` ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}
        `,
      },
      variations: {
        condition:
          ((((form.isVariated && form.isVariated != "false") ||
            form.isVariated == "true") &&
            form.variations.length) ||
            form.isVariated == "false" ||
            !form.isVariated) &&
          variationValidation,
        error: Translater.ErrorVariations[language.slug],
      },
      // weight: {
      //   condition:
      //     (parseInt(form.weight) >= 0 &&
      //       (form.isVariated == "false" || !form.isVariated)) ||
      //     (form.isVariated && form.isVariated != "false") ||
      //     form.isVariated == "true",
      //   error: ` ${Translater.ErrorLength[language.slug]}:
      //   ${Translater.TableTitles.weight[language.slug]}`,
      // },
      // price: {
      //   condition:
      //     (+form.price > 0 &&
      //       (form.isVariated == "false" || !form.isVariated)) ||
      //     (form.isVariated && form.isVariated != "false") ||
      //     form.isVariated == "true",
      //   error: ` ${Translater.ErrorLength[language.slug]}:
      //   ${Translater.TableTitles.price[language.slug]}`,
      // },
      restaurant: {
        condition: !!form.restaurant,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
    }),
    [form, Translater, language]
  );
  const { errors, validation } = useValidation(schema);

  const [options, setOptions] = useState<IOptions[]>([]);

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;

      //@ts-ignore
      if (type === "file")
        //@ts-ignore
        setOptionValue({ ...optionValue, photo_option: e.target.files[0] });
      else {
        setOptionValue({ ...optionValue, [name]: value });
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "name") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false;

        if (isTitleInArr) {
          const name = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            name: name as { value: string; lang: string }[],
          });

          return;
        }

        const name = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          name: name as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;
      //@ts-ignore
      if (type === "file") setForm({ ...form, photo: e.target.files[0] });
      else if (type === "number") {
        if (+value >= 0) setForm({ ...form, [name]: value });
      } else setForm({ ...form, [name]: value });
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name == "weight" && +e.target.value >= 0) {
        setForm({ ...form, [e.target.name]: e.target.value });
        return;
      }

      if (e.target.name == "price" && +e.target.value >= 0) {
        setForm({ ...form, [e.target.name]: e.target.value });
        return;
      }

      setForm({ ...form, [e.target.name]: e.target.value });
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue]);
      setOptionValue({
        option: "",
        photo_option: "",
        price_option: "0",
        additions: "",
      });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      if (
        name == "measurement" &&
        value == Translater.Select.gramms[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: "uk", value: Translater.Select.gramms.uk },
            { lang: "en", value: Translater.Select.gramms.en },
          ],
        });
        return;
      }

      if (
        name == "measurement" &&
        value == Translater.Select.ml[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: "uk", value: Translater.Select.ml.uk },
            { lang: "en", value: Translater.Select.ml.en },
          ],
        });
        return;
      }

      setForm({ ...form, [e.target.name]: e.target.value });
    },
    removeHandler: (id: string) => {
      let similar: IProduct[] = [...(primary?.similar as IProduct[])];

      similar = similar.filter((item) => item._id !== id);

      //@ts-ignore
      setPrimary({ ...primary, similar });
    },
    setCategory: (category: string) => {
      setForm({ ...form, category });
    },
    saveHandler: async () => {
      try {
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },

    deleteOptionHandler: (id: string) => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.DeleteOptions(id);
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IProduct = await request(
          `${config.API}/products/${id}/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          setDefaultAdditions(response.additions);
          let parent = null;
          if (!!response.parent) {
            parent = await request(
              `${config.API}/products/${response.parent}`,
              "GET",
              null,
              {
                Authorization: `Bearer ${token as string}`,
              }
            );
          }

          const formData = {
            ...response,
            parent: parent && parent._id,
            restaurant: response.restaurant,
          };

          setMetaDescription(response.metaDescription as string);
          setMetaKeywords(response.metaKeywords as string[]);

          setPrimary(formData);
          setForm(formData);
        }
      } catch (e) {}
    },
    SetSimilar: async (): Promise<boolean> => {
      return !!false;
    },
    Save: async () => {
      try {
        const data = new FormData();

        data.append("name", JSON.stringify(form.name));
        data.append("description", JSON.stringify(form.description));

        if (photo) {
          data.append("photo", photo);
        }

        form.price && data.append("price", form.price as string);
        data.append("isVariated", form.isVariated as string);
        data.append("metaDescription", metaDescription);
        data.append("measurement", JSON.stringify(form.measurement));

        if (metaKeywords.length) {
          metaKeywords.map((el) => data.append("metaKeywords[]", el));
        }

        data.append("hidden", form.hidden as string);

        if (form.category) data.append("category", form.category._id as string);

        if (form.parent) {
          data.append("parent", form.parent);
        }

        data.append("weight", form.weight as string);
        data.append("novelty", form.novelty as string);
        data.append("restaurant[]", form.restaurant._id);

        if (form.additions) {
          for (let ad of form.additions) {
            // addsArray.concat(ad._id)
            data.append("additions[]", ad._id);
          }
        }

        if (form.variations.length > 0) {
          for (let varData of form.variations) {
            if (!varData._id) {
              toogleIsAlertOpen(true);
              return;
            }
            data.append("variations[]", JSON.stringify(varData._id));
          }
        }

        await request(`${config.API}/products/${primary?._id}`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        });

        history.goBack();
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/products/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/products");
      } catch (e) {
        console.log(e);
      }
    },

    DeleteOptions: async (id: string) => {
      try {
        await request(`${config.API}/products/${id}`, "PUT", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/products");
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
  }, [id]);

  useEffect(() => {
    const prevIsValid = { ...isValid };

    prevIsValid.name = true;
    prevIsValid.description = true;

    toggleValid(prevIsValid);
  }, [form.name, form.description]);

  useEffect(() => {
    primary &&
      setForm({ ...form, variations: primary?.variations as IVariation[] });
  }, [primary]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper
      footer
      title={
        primary.name.map((item) => {
          if (item.value) return item.value;
        })[0] as string
      }
    >
      <Page.Header
        backButtonTitle={Translater.ProductsDetailPage.title[language.slug]}
        backButtonLink="/products"
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        metaKeywords={metaKeywords}
        setMetaKeywords={setMetaKeywords}
        metaDescription={metaDescription}
        setMetaDescription={setMetaDescription}
        showPhoto
        form={form}
        setForm={setForm}
        optionValue={optionValue}
        options={options}
        data={primary}
        setCategory={Events.setCategory}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
        defaultAdds={defaultAdditions}
        setDefaultAdds={setDefaultAdditions}
        valueLang={valueLang}
        isDetailPage
        setPhoto={setPhoto}
      />

      <Form.DateBlock
        updated={primary.updatedAt as string}
        created={primary.createdAt as string}
      />
      <Fragment>
        <Buttons.Container
          disabled={loading}
          position={"relative"}
          deleteHandler={access.products.delete ? Events.deleteHandler : false}
          saveHandler={access.products.change ? Events.saveHandler : false}
        />
      </Fragment>

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
          <p>{`Натисніть на додати параметр`}</p>
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
