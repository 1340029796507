import React, { Fragment, useState, useEffect, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { config, Translater } from '../../../../config'
import { useHttp } from '../../../../hooks/http.hook'
import { ICategory } from '../../../../types/items'
import { ITrackProps } from './types'
import { SortableList } from './components'
import { getStringFromTranslationsArr } from '../../../../helpers'

const Track: React.FC<ITrackProps> = ({
  data,
  nesting,
  reloadHandler,
  subTrack = false,
}) => {
  const history = useHistory()

  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [showChildren, toggleChildren] = useState<boolean>(false)
  const [children, setChildren] = useState<ICategory[]>([])

  const Events = {
    childrenHandler: () => {
      if (!loading) toggleChildren(!showChildren)
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    editHandler: () => {
      history.push(`/categories/${data._id}`)
    },
  }

  const Callbacks = {
    FetchChildren: async () => {
      try {
        const response: ICategory = await request(
          `${config.API}/categories/${data._id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response.children) setChildren(response.children)
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${data._id}`, 'DELETE', null, {
          Authorization: `Bearer ${token as string}`,
        })

        reloadHandler()
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.FetchChildren()
  }, [])

  return (
    <Fragment>
      <Table.Track
        type='categories'
        clickHandler={Events.childrenHandler}
        tableTrack={!subTrack}
      >
        <Table.Cell
          type='custom'
          style={{
            paddingLeft: (nesting + 1) * 20,
          }}
        >
          <SortableList.DragHandle />
        </Table.Cell>

        <Table.Cell
          type='image'
          imageURL={`${config.imageProxy}public/${data.photo}`}
        />

        <Table.Cell
          text={getStringFromTranslationsArr(data.title, language.slug)}
        />

        <Table.Cell
          text={
            data.hidden
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
          }
        />

        <Table.Cell
          style={{
            paddingRight: (nesting + 1) * 30,
          }}
          text={new Date(data.updatedAt as string).toLocaleDateString()}
        />

        <Table.Cell
          style={{
            paddingRight: (nesting + 1) * 30,
          }}
          text={new Date(data.updatedAt as string).toLocaleDateString()}
        />

        <Table.Cell text={`${data?.products?.length}` ?? '0'} />

        <Table.Cell
          type='control'
          editButton
          deleteButton={access.categories.delete && true}
          editHandler={Events.editHandler}
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>

      {showChildren && children
        ? children.map((child) => (
            <Track
              key={child._id}
              data={child}
              nesting={nesting + 1}
              reloadHandler={reloadHandler}
              subTrack
            />
          ))
        : null}
    </Fragment>
  )
}

export default Track
