import React from "react"
import { IItemProps } from "./types"
import Trash from "../../assets/icons/delete.png"
import up from "../../assets/images/up.png"
import down from "../../assets/images/down.png"

const Item: React.FC<IItemProps> = ({
  fields,
  data,
  idx,
  deleteHandler,
  deleteField,
  slugs = [],
  notDel = false,
  arrows = false,
  arrowsHandler = (val: number) => undefined,
}) => {
  const getSlug = (i: any) => {
    return i == "price" ? "грн" : i == "weight" ? "г" : ""
  }

  const changePositionHandler = (e: any) => {
    arrowsHandler(e.target.alt == "up" ? idx - 1 : idx + 1, idx)
  }

  return (
    <div key={idx} className="page-item">
      №{idx + 1} -{" "}
      {fields.map((i, idx) => {
        return `${!!data[i] ? data[i] : ""}${data && getSlug(i)}${
          data && !!slugs.length ? slugs?.[idx] : ""
        } `
      })}
      {arrows &&
        //@ts-ignore
        arrowsHandler && (
          <div className="arrows">
            <img src={up} alt="up" onClick={changePositionHandler} />
            <img src={down} alt="down" onClick={changePositionHandler} />
          </div>
        )}
      {!notDel && (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => deleteHandler(data && data[deleteField])}
        >
          <img className="trash-icon" src={Trash} />
        </a>
      )}
    </div>
  )
}

export default Item
